import moment from 'moment'
import layout from 'layouts/default'
import Bus from 'bus'
import { screenSizes, searchTokens } from 'services'
import searchModal from 'layouts/default/partials/searchModal'
import imageBg from 'components/imageBg'
import tripadvisorRating from 'components/tripadvisorRating'
import howItWorks from 'layouts/default/partials/howItWorks'
import searchFilters from 'layouts/default/partials/searchFilters'
import searchCriteria from 'layouts/default/partials/searchCriteria'
import numberOfCars from 'layouts/default/partials/numberOfCars'
import { stripTags } from 'filters'
import { currencySymbol } from 'filters'
import confirm from 'components/modals/confirm'

let components = {
  layout,
  searchModal,
  imageBg,
  searchFilters,
  searchCriteria,
  numberOfCars,
  howItWorks,
  tripadvisorRating,
  confirm,
}

if (!isServer) {
  components.googleMapsResults = require('components/googleMapsResults').default;
  components.rating = require('vue-star-rating').default;
}

const filters = {
  // currencySymbol
}

const watch = {
  currencyCode (n, o) {
    if (n && n != o)
    this.init(this.query);
    console.log('currencyCode xxx', n, o);
  },
}


const methods = {
  
  getFilteredResults () {
    console.log('this.filters.amenities', this.filters.amenities);
    this.filteredResults = _.filter(this.results, (item, index) => {
      let amenities = _.map(item.amenities, amenity => amenity.id);
      return (item.price >= this.filters.price[0] && item.price <= this.filters.price[1])
      && item.distance_from_airport <= this.filters.distance
      && (inArray(amenities, this.filters.amenities) || !this.filters.amenities.length)
    })


    function inArray (arr, searchFor) {
      let founds = [];
      for (let i in searchFor) {
        for (let j in arr) {
          if (searchFor[i] == arr[j]) {
            founds.push(i);
            break;
          }
        }
      }

      if (founds.length && founds.length == searchFor.length) {
        return true;
      }

      return false;
    }
  },

  featuredPhoto (location) {
    if (!location.photos || !location.photos.length) return null

    let type = 'facility';
    if (this.query.reservation_type && this.query.reservation_type != 'both') {
      type = this.query.reservation_type;
    }

    let photo_obj = location.photos.filter(photo => ((photo.type == type) && photo.featured));

    if (photo_obj && photo_obj.length) {
      return photo_obj[0].filename
    }

    return location.photos ? location.photos[0].filename : null
  },
  onMouseOverItem (location) {
    //used in component google-maps-results
    Bus.$emit('search.mouseEnterLocation', location);
  },
  onMouseOutItem (location) {
    //used in component google-maps-results
    Bus.$emit('search.mouseOutLocation', location);
  },

  onMouseEnterIcon (marker) {
    this.$refs['location_' + marker.id][0].classList.add('hover')
  },

  onMouseOutIcon (marker) {
    this.$refs['location_' + marker.id][0].classList.remove('hover')
  },

  onClickIcon (marker) {
    console.log(this.$refs['location_' + marker.id]);
    
    $('html, body').animate({
      scrollTop: $(this.$refs['location_' + marker.id][0]).offset().top
    }, 500);
  },
  
  currencySymbol (code) {
    return currencySymbol(code)
  },
  onMapMoved (e) {
    console.log('map moved', e);
  },
  toggleFilters () {
    if (this.disableFilters) return;

    this.filtersActive = !this.filtersActive;
    // this.setPriceSlider();

  },
  hideFilters (event) {
    this.filtersActive = false;
  },
  openSearchModal () {
    this.canShowSearchModal = true;
    this.$nextTick(() => {
      this.$refs.searchModal.open();
    })
  },
  scrollToSearchResult() {
    if (this.$route.name == 'search' && document.documentElement.clientWidth < 575) {
      const el = this.$refs.searchResults;
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
  onCloseModal () {
    this.canShowSearchModal = false;
  },
  getIndexByLocationId(array, value) {
    for (var i = 0; i < array.length; i++) {
        if (array[i].id == value) {
            return i;
        }
    }
    return -1;
  },
  getMinPrices (query) {
    let data = _.cloneDeep(query);

    console.log('min prices', data);

    data.from_date = moment(data.from_date, ['DD/MMM/YYYY']).format('YYYY-MM-DD');
    data.to_date = moment(data.to_date, ['DD/MMM/YYYY']).format('YYYY-MM-DD');

    let promises = []
    this.results = _.cloneDeep(this.searchResults.results)
    this.filteredResults = _.cloneDeep(this.searchResults.results)
    if (this.results) {
      this.results.forEach((location, i) => {
        this.$set(location, 'price', null);
        this.$set(location, 'sold_out', false);
        this.$set(location, 'warning', null);
        try {
          if (this.filteredResults[i]) {
            this.$set(this.filteredResults[i], 'price', null);
            this.$set(this.filteredResults[i], 'sold_out', false);
            this.$set(this.filteredResults[i], 'warning', null);
          }
        } catch(e) {}
        // console.log('currencyCode ccc', this.currencyCode);
        let promise = this.$store.dispatch('locations.getMinGrandTotal', {
          id: location.id,
          data,
          currencyCode: this.currencyCode
        });
        this.$set(this.calculationLoadings, location.id, true)
        promise.then(() => {

          this.$set(this.calculationLoadings, location.id, false)
          let totals = _.cloneDeep(this.$store.state.locations.totals)
          let tokens = {};

          for (let i in totals) {
            let index = this.getIndexByLocationId(this.results, totals[i].id)
            if (!tokens[totals[i].id]) {
              tokens[totals[i].id] = totals[i].token;
            }
            if (location.id == totals[i].id) {
              if (totals[i].error) {
                let err_msg = totals[i].error;
                if (totals[i].errors && totals[i].errors['items.0.from_date']) {
                  err_msg = totals[i].errors['items.0.from_date'][0];
                }
                this.$set(location, 'error', err_msg);
              }

              if (totals[i].sold_out) {
                this.$set(location, 'sold_out', true);
                if (this.filteredResults[index]) {
                  this.$set(this.filteredResults[index], 'sold_out', true);
                }
              } else {
                this.$set(location, 'sold_out', totals[i].reservation ? totals[i].reservation.sold_out : false);
                this.$set(location, 'price', totals[i].reservation ? totals[i].reservation.grand_total : null);
                this.$set(location, 'warning', totals[i].warning);

                try {
                  if (this.filteredResults[index]) {
                    this.$set(this.filteredResults[index], 'price', totals[i].reservation ? totals[i].reservation.grand_total : null);
                  }
                } catch (e) {}
              }
              break;
            }
          }
          searchTokens.set(tokens)
          for (let i in this.markers) {
            if (location.id == this.markers[i].id) {
              this.markers[i].sold_out = location.sold_out;
              if (location.sold_out && !location.price) {
                this.markers[i].label.text = 'Sold Out'
              } else if(location.price) {
                if (this.currencyCode) {
                  this.markers[i].label.text = currencySymbol(this.currencyCode) + location.price.toFixed()
                } else {
                  this.markers[i].label.text = currencySymbol(location.currency_code) + location.price.toFixed()
                }
              }
              this.$set(this.markers, i, this.markers[i])
              break;
            }
          }
        });

        promises.push(promise);
      });

      return (Promise.all(promises)).then( () => {
        if (this.results.length) {

            let minPrice = this.results[0].price;
            let maxPrice = this.results[0].price;
            let maxDistance = this.results[0].distance_from_airport;

            _.forEach(this.results, location => {
              if (location.price < minPrice) {
                minPrice = location.price;
              }

              if (location.price > maxPrice) {
                maxPrice = location.price;
              }

              if (location.distance_from_airport > maxDistance) {
                maxDistance = location.distance_from_airport;
              }
            });

            console.log('max price', maxPrice)

            minPrice = (Math.floor(minPrice)) - 1;
            maxPrice = (Math.ceil(maxPrice)) + 1;

            console.log('max price', maxPrice)
            maxDistance = (Math.ceil(maxDistance)) +1;

            this.priceSlider = [];
            this.priceSlider.push(minPrice);
            this.priceSlider.push(maxPrice);
            this.distanceSlider = maxDistance;
            this.filters.price = this.priceSlider;
            this.filters.distance = this.distanceSlider;
            this.$nextTick(() => {
              this.scrollToSearchResult();
            })
        }
      });
    }

    return Promise.resolve();
  },
  applyFilters (filters) {
    this.filters = filters;
    this.getFilteredResults()
    this.setMarkers()

    if (filters.numberOfSpots != this.query.number_of_spots) {

      if (typeof this.query.guests_in_rooms !== "string") {
        this.query.guests_in_rooms = JSON.stringify(this.query.guests_in_rooms);
      }

      this.query.number_of_spots = filters.numberOfSpots;
      this.numberOfSpots = filters.numberOfSpots;

      this.$router.push({
        name: 'search',
        query: this.query
      });
    }

  },
  updateAmenities (amenities) {
    console.log('inside_update_amenities', amenities);
    this.sharedAmenities.active = amenities
    console.log('updateAmenities', amenities)
  },
  changeSortBy (value) {
    this.sort_by = value;
    let sort_by = 'reservations_count';
    let sort_type = 'desc';

    if (value == 'cheapest') {
      sort_by = 'price'
      sort_type = 'asc'
    } else if (value == 'closest') {
      sort_by = 'distance_from_airport'
      sort_type = 'asc'
    }

    this.filteredResults = _.orderBy(this.filteredResults, ['sold_out', sort_by], ['asc', sort_type]);
    this.results = _.orderBy(this.results, ['sold_out', sort_by], ['asc', sort_type]);
  },
  setMarkers () {
    if (this.filteredResults.length) {
      this.markers = this.filteredResults.map((location) => {
        let price = location.price ? currencySymbol(location.currency_code) + location.price.toFixed(2) : '...';

        return {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
          id: location.id,
          label: {
            text: price,
            fontSize: '10px'
          }
        }
      });
    }
  },
  handleScroll(e) {
    if (window.innerWidth <= screenSizes.s_mobile) {
      $('.map-col').removeClass('sticky')
      return
    }
    // The height from the top of the browser
    let height = 219;
    if (window.scrollY >= height ) {
      $('.map-col').addClass('sticky');
    } else {
      $('.map-col').removeClass('sticky')
    }
  },

  goToLocation (location, i, e) {  
    if (e.target.classList.contains('check-prices')) return;

    if (!isServer) {
      dataLayer.push({
        'event': 'locationClick',
        'ecommerce': {
          'click': {
            'actionField': {
              'list': this.$route.params.slug ? 'Search results' : 'Airport page'
            }, // Optional list property.
            'products': [{
              'name': location.name, // Name or ID is required.
              'id': location.id,
              'price': location.price,
              'category': this.airport.name,
              'position': i + 1
            }]
          }
        }
      });
    }

    let {href} = this.$router.resolve(this.locationUrl(location))
    window.open(href, '_blank')
  },

  locationUrl (location) {
    let query = _.cloneDeep(this.query);
    query.guests_in_rooms = JSON.stringify(query.guests_in_rooms);

    return {
      name: 'location',
      params: {
        slug: location.url || location.id
      },
      query
    }
  },

  changeNumberOfSpots (value) {
    console.log('number_of_spots cahaneeeeee', value)
    this.numberOfSpots = value;
    this.filters.numberOfSpots = value;
    this.applyFilters(this.filters);
  },

  showTripadvisorReviews (location) {
    return  location.tripadvisor_id &&
      ( this.query.reservation_type == 'room' ||
        this.query.reservation_type == 'both' ) &&
      !_.isEmpty(location.tripadvisor_reviews);
  },

  getReviews () {
    let promises = [];
    if (this.results) {
      this.results.forEach((location, i) => {
        if (location.tripadvisor_id) {
          let promise = this.$store.dispatch('locations.getTripadvisorReviews', location.id).then(() => {
            let tripadvisor = this.$store.state.locations.active.tripadvisor_reviews;
            this.$set(location, 'tripadvisor_reviews', tripadvisor);
            this.$set(this.filteredResults[i], 'tripadvisor_reviews', tripadvisor);
            
          });
          promises.push(promise)
        }
      });
    }
  },

  init (query) {
    this.disableFilters = true;
    console.log('this.searchResults.results', this.searchResults.results);
    
    this.filteredResults = _.cloneDeep(this.searchResults.results);
    
    if (!this.$route.params.slug) {
      this.getMinPrices(query).then(() => {
        this.disableFilters = false;
        this.changeSortBy(this.sort_by);
        this.getFilteredResults()

        
        if (!isServer) {
          let impressions = [];
          this.results.forEach((location, i) => {
            impressions.push({
              'name': location.name,
              'id': location.id,
              'price': location.price,
              'category': this.airport.name,
              'list': 'Search Results',
              'position': i + 1
            });
          });
  
          dataLayer.push({
            'ecommerce': {
              'currencyCode': this.currencyCode, // Local currency is optional.
              'impressions': impressions
            }
          });
        }

        console.log('sorted');
      })
    }

    this.getReviews();

    this.center = {
      lat: parseFloat(this.airport.latitude) || 0,
      lng: parseFloat(this.airport.longitude) || 0
    }

    if (!_.isEmpty(this.filteredResults)) {
      this.setMarkers();
    }
    let currency = _.cloneDeep(this.$store.state.search.default_currency);
    if (currency && this.currencyCode != this.filteredResults[0].currency_code) {
      this.currencyChangedAlert();
    }
  },

  shouldShowAmenity (type) {
    if (type == this.query.reservation_type || type == 'facility' || this.query.reservation_type == 'both' || !this.query.reservation_type) {
      return true;
    }

    return false;
  },

  currencyChangedAlert() {
    this.$refs.confirm.confirm("You are viewing locations in " + this.currencyCode + ", would you like to change to the location's default currency (" + this.filteredResults[0].currency_code + ")?", () => {
      this.$store.dispatch('currencies.save', this.filteredResults[0].currency_code);
    },() => {},'Yes','No')
  }
}

const computed = {
  searchResults () {    
    return _.cloneDeep(this.$store.state.search.results);
  },

  airport () {
    return this.$store.state.airports.active;
  },

  currencyCode () {
    return _.cloneDeep(this.$store.state.currencies.selected);
  }
}

export default {
  name: 'airport-page',
  props: ['query'],
  components,
  computed,
  methods,
  filters,
  watch,
  data () {
    return {
      stripTags,
      moment,
      results: [],
      filteredResults: [],
      sort_by: 'most_popular',
      canShowSearchModal: false,
      disableFilters: true,
      calculationLoadings: {},
      center: {lat: 0, lng: 0},
      markers: [],
      priceSlider: [
        0,
        20
      ],
      amenities: {
        list: [],
        active: []
      },
      sharedAmenities: {
        list: [],
        active: []
      },
      distanceSlider: 0,
      filtersActive: false,
      numberOfSpots: this.query.number_of_spots || 1,
      mapActive: false, // 'map',
      filters: {
        price: [],
        distance: null,
        amenities: []
      },
      prev_scroll: 0
    }
  },

  created () {
    console.log('created ---------->>>>>>>>>>', this.query);
    this.$store.dispatch('amenities.getAll',{_all: true}).then(() => {
      this.amenities.list = _.cloneDeep(this.$store.state.amenities.all);
      this.sharedAmenities.list = _.cloneDeep(this.$store.state.amenities.all);
    });
  },

  mounted () {
    this.init(this.query);

    $('[data-toggle="tooltip"]').tooltip();
    this.$nextTick(function() {
      window.addEventListener('resize', this.hideFilters);
      //Init
      this.hideFilters();
    })
    window.addEventListener('scroll', this.handleScroll);

  },
  beforeDestroy () {
    window.removeEventListener("resize", this.hideFilters);
    window.removeEventListener('scroll', this.handleScroll);
  }
}
