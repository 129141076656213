import { http } from 'services'
// import axios from 'axios'
// initial state
const state = () => ({
  all: {},
  selected: null,
  errors: null,
})

// getters
const getters = {
}

// actions
const actions = {
  ['currencies.getAll'] ({ commit , dispatch}, id) {
    commit('CLEAR_ERRORS')
    return (http.get('currencies?_all=true')).then((res) => {
      commit('CURRENCIES_GET_ALL', res);
      
      let storedCurrency = localStorage.getItem('selected_currency');
      if (!storedCurrency) {
        let defaultCurrency = _.find(res.data, (item, index) => {
          return item.default;
        });  
        if (defaultCurrency) {
          dispatch('currencies.save', defaultCurrency.code);  
        }
      }
    });
  },

  ['currencies.get'] ({ commit }) {
    commit('CLEAR_ERRORS')
    let selected_currency = localStorage.getItem('selected_currency');
    commit('CURRENCIES_GET', selected_currency);

  },

  ['currencies.save'] ({ commit }, currency) {
    commit('CLEAR_ERRORS')
    if (!!currency) {
      localStorage.setItem('selected_currency', currency);
      commit('CURRENCIES_SET', currency);
    } else {
      localStorage.removeItem('selected_currency')
      commit('CURRENCIES_SET', null);
    }
  },

}

// mutations
const mutations = {
  CURRENCIES_GET_ALL (state, res) {
    if (res.data) {
      state.all = res.data;
    }
  },

  CURRENCIES_GET (state, res) {
    if (res) {
      state.selected = res;
    }
  },

  CURRENCIES_SET (state, res) {
    state.selected = res;
  },
  //ERRORS
  CURRENCIES_ERROR (state, err) {
    state.errors = err.response.data;
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
