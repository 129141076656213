import moment from 'moment'
import toastr from 'toastr'
import inputIcon from 'components/inputIcon/inputIcon.vue'
import datepicker from 'components/datepicker'
// import autocomplete from 'components/autocomplete'
import sSelect from 'components/sSelect2'
import { urlDataValidator } from 'services'
import numberOfRooms from '../numberOfRooms'

const components = {
  inputIcon,
  datepicker,
  numberOfRooms,
  sSelect
  // autocomplete
}

const methods = {
	isActive(item) {
		return item == this.active;
	},

	setActive(item) {
		this.active = item;
    console.log('this.form.reservation_type', item);
    if (item == 'hotel') {
      this.form.reservation_type = 'room'
    } else if (item == 'parking') {
      this.form.reservation_type = 'parking'
    } else {
      this.form.reservation_type = 'both'

      if(!this.form.nights_to_sleep) {
        this.form.nights_to_sleep = null
      }
    }
	},
  onChangeFromDate (value) {
    this.$nextTick(() => {
      this.$refs.toDateField.$el.focus();
      let to_date = moment(this.form.to_date, ['DD/MMM/YYYY']);
      if (moment(value, ['DD/MMM/YYYY']).isAfter(to_date)) {
        this.form.to_date = value
      }
    });
  },
  submitSearch () {
    if (this.timeout) return;

    let query = _.cloneDeep(this.form)
    let error = false;

    if (!query.airport_id && !this.airport) {
      toastr.error('Please enter departure airport');
      error = true;
    }

    if (!query.from_date) {
      toastr.error('From date field is required');
      error = true;
    }

    if (!query.to_date) {
      toastr.error('To date field is required');
      error = true;
    }



    if (error) return;

    if (query.reservation_type == 'parking') {
      delete query.nights_to_sleep
      delete query.number_of_rooms
      delete query.guests_in_rooms
    } else if (query.reservation_type == 'room') {
      delete query.number_of_spots
      delete query.nights_to_sleep
    }

    if (query.guests_in_rooms) {
      query.guests_in_rooms = JSON.stringify(query.guests_in_rooms);
    }

    if (!query.reservation_type) {
      query.reservation_type = "both"
    }

    if (query.reservation_type == 'both') {
      if (!query.nights_to_sleep) {
        toastr.error('Nights to sleep field is required');
        error = true;
        return;
      }
    }

    let routeName = this.routeName;



    try {
      if (this.airport.id != query.airport_id) {
        routeName = 'search';
      }
    } catch (e) {
      console.log('airport error', e);
    }

    let params = {}
    console.log('routeName', routeName);
    console.log('query.location_id', query.location_id);
    if (query.location_id && routeName == 'location') {
      params.slug = query.location_id;
    }

    this.$router.push(Object.assign({}, this.$route, {
      name: routeName,
      query,
      params
    }));

    if (this.modal) {
      this.close();
    }

    //to prevent frequant clinking
    this.timeout = setTimeout(() => {
      clearTimeout(this.timeout);
      this.timeout = null;
    }, 1500);
  },

  getAirports () {
    this.$store.dispatch('airports.getAll').then(() => {
      this.setAirports()

      // if (this.airports_options[0] && !this.selectedAirport.value) {
      //   this.selectedAirport = this.airports_options[0]//
      // }
    });
  },

  setAirports () {
    this.airports_options = _.map(this.airports, (item) => {
      let obj = {
        text: item.name + ` (${item.code})`,
        value: item
      }

      if (this.form.airport_id && item.id == this.form.airport_id) {
        this.selectedAirport = item.name + ` (${item.code})`
      }

      return obj
    })

    this.airports_options = _.orderBy(this.airports_options, ['text'], ['asc']);
  },

  onSelectAirport (item) {
    if(item) {
      // this.selectedAirport = item.text
      this.form.airport_id = item.value.id;
    } else {
      this.form.airport_id = null;
      // this.selectedAirport = null;
    }
  },

  // onSelectAirport ({item, input}) {
  //   this.form.airport_id = item.id;
  // },

  // formatAirportResults (item) {
  //   console.log('item', item);
  //   if (!item) return null;
  //   return item.name ? `${item.name} (${item.code})` : null
  // },

  onChangeNumberOfRooms ({rooms, guests}) {
    this.form.number_of_rooms = rooms
    this.form.guests_in_rooms = guests
    console.log('this.form.number_of_rooms', this.form.number_of_rooms);
  }
}

const computed = {
  airports () {
    return _.cloneDeep(this.$store.state.airports.all)
  }
}

const watch = {
  formData (n) {
    this.form = n;

    if (n.reservation_type == 'parking') {
      this.active = 'parking'
    } else if (n.reservation_type == 'room') {
      this.active = 'hotel'
    } else {
      this.active = 'hotel-parking'
      if(!this.form.nights_to_sleep) {
        this.form.nights_to_sleep = null
      }
    }

    this.form.number_of_rooms = urlDataValidator.validateNoOfRoomsAndParking(this.form.number_of_rooms)
    this.form.number_of_spots = urlDataValidator.validateNoOfRoomsAndParking(this.form.number_of_spots)
    this.form.guests_in_rooms = urlDataValidator.validateGuestsInRooms(this.form.guests_in_rooms, this.form.number_of_rooms);

    if (!this.form.number_of_spots) this.form.number_of_spots = 1;
  }
}

export default {
  name: 'search-form',
  props: ['modal', 'close', 'formData', 'airport', 'routeName'],
  components,
  methods,
  watch,
  computed,
  data () {
    let active = 'parking';
    if (this.formData) {
      if (this.formData.reservation_type == 'both') {
        active = 'hotel-parking'
      } else if (this.formData.reservation_type == 'room') {
        active = 'hotel'
      }
    }

    let form = {
      airport_id: this.formData ? (this.formData.airport_id || null) : null,
      location_id: this.formData ? (this.formData.location_id || null) : null,
      nights_to_sleep: this.formData ? (this.formData.nights_to_sleep || null) : null,
      from_date: this.formData ? (this.formData.from_date || null) : null,
      to_date: this.formData ? (this.formData.to_date || null) : null,
      reservation_type: this.formData ? (this.formData.reservation_type || 'parking') : 'parking',
    }

    if (!form.airport_id && this.airport) {
      form.airport_id = this.airport.id;
    }

    form.number_of_rooms = this.formData
      ? (this.formData.number_of_rooms
        ? urlDataValidator.validateNoOfRoomsAndParking(this.formData.number_of_rooms)
      : 1)
    : 1,
    form.guests_in_rooms = this.formData
      ? this.formData.guests_in_rooms
        ? urlDataValidator.validateGuestsInRooms(this.formData.guests_in_rooms, form.number_of_rooms)
      : [2]
    : [2],
    form.number_of_spots = this.formData
      ? this.formData.number_of_spots
        ? urlDataValidator.validateNoOfRoomsAndParking(this.formData.number_of_spots)
      : 1
    : 1

    return {
      active,
      timeout: null,
      airports_options: [],
      selectedAirport: null,
      form
    }
  },
  created () {    
    if (_.isEmpty(this.airports)) {
      this.getAirports();
    } else {
      this.setAirports();
    }
  },
  mounted () {
  }
}
