var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { staticClass: "search-page", attrs: { "no-footer": true } },
    [
      _c(
        "div",
        { staticClass: "search-header" },
        [
          _c("search-criteria", {
            staticClass:
              "grey padding-left-6x padding-right-6x padding-top-2x padding-bottom-2x",
            attrs: {
              query: _vm.query,
              "open-search-modal": _vm.openSearchModal
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "airport-holder wrapper wrapper-overflow" },
            [
              _c("div", { staticClass: "airport-holder--header" }, [
                _c("h1", { staticClass: "airport-holder--title" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.airport.name) + "\n          "
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openSearchModal($event)
                        }
                      }
                    },
                    [_vm._v("(Change Airport)")]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-xl-none d-lg-none airport-holder--icons" },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info text-white btn-md airport--holder-icons-button",
                        class: _vm.mapActive === true ? "active" : "",
                        on: {
                          click: function($event) {
                            _vm.mapActive = true
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-map",
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info text-white btn-md airport--holder-icons-button",
                        class: _vm.mapActive === false ? "active" : "",
                        on: {
                          click: function($event) {
                            _vm.mapActive = false
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-th-list",
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  ]
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "results-holder" },
        [
          _c(
            "div",
            {
              staticClass: "results-col",
              class: _vm.mapActive === false ? "active-sm-xs" : "none"
            },
            [
              _c(
                "div",
                { staticClass: "search-results" },
                [
                  _c("div", { staticClass: "filters bg-grey" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center"
                      },
                      [
                        _vm.query.reservation_type != "room"
                          ? _c("number-of-cars", {
                              staticClass: "search--number-of-cars",
                              attrs: {
                                "number-of-spots": _vm.numberOfSpots,
                                disabled: _vm.disableFilters
                              },
                              on: { change: _vm.changeNumberOfSpots }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "sortby" }, [
                          _c("div", { staticClass: "btn-group w-100" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-block dropdown-toggle bg-white",
                                class: { disabled: _vm.disableFilters },
                                attrs: {
                                  type: "button",
                                  disabled: _vm.disableFilters,
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Sort by:\n                "
                                ),
                                _vm.sort_by == "most_popular"
                                  ? _c("span", [_vm._v("Most Popular")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.sort_by == "cheapest"
                                  ? _c("span", [_vm._v("Cheapest Rate")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.sort_by == "closest"
                                  ? _c("span", [_vm._v("Closest Hotel")])
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "dropdown-menu" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class: {
                                    active: _vm.sort_by == "most_popular"
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.changeSortBy("most_popular")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  Most Popular\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class: { active: _vm.sort_by == "cheapest" },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.changeSortBy("cheapest")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  Cheapest Rate\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class: { active: _vm.sort_by == "closest" },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.changeSortBy("closest")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  Closest Hotel\n                "
                                  )
                                ]
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "trigger-filters d-none d-md-block" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-link",
                                attrs: { disabled: _vm.disableFilters },
                                on: {
                                  click: function($event) {
                                    _vm.toggleFilters()
                                  }
                                }
                              },
                              [_vm._v("Filter Results")]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      ref: "searchResults",
                      staticClass: "search-results--items",
                      attrs: { id: "searchResults" }
                    },
                    [
                      _vm._l(_vm.filteredResults, function(result, $index) {
                        return _c(
                          "li",
                          {
                            key: result.id,
                            ref: "location_" + result.id,
                            refInFor: true,
                            staticClass: "search-results--item",
                            on: {
                              mouseenter: function($event) {
                                _vm.onMouseOverItem(result)
                              },
                              mouseleave: function($event) {
                                _vm.onMouseOutItem(result)
                              }
                            }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "wrapper wrapper-overflow search-results--item-link",
                                attrs: {
                                  to: _vm.locationUrl(result),
                                  target: "_blank"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.goToLocation(result, $index, $event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "search-results--image-container"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "search-results--image",
                                      attrs: {
                                        src: _vm.featuredPhoto(result)
                                          ? _vm.featuredPhoto(result)
                                          : "/assets/images/not-found.png"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "search-results--details-container "
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "search-results--details"
                                      },
                                      [
                                        _c(
                                          "h4",
                                          {
                                            staticClass: "search-results--title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(result.name) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.showTripadvisorReviews(result)
                                          ? _c("tripadvisor-rating", {
                                              staticClass:
                                                "search-results--rating search-results--rating-container stars d-none d-md-flex",
                                              attrs: {
                                                location_link:
                                                  result.tripadvisor_reviews
                                                    .web_url,
                                                rating:
                                                  result.tripadvisor_reviews
                                                    .rating,
                                                reviews_count:
                                                  result.tripadvisor_reviews
                                                    .num_reviews,
                                                font_size: "12px",
                                                show_icon: true
                                              }
                                            })
                                          : _c("div", {
                                              staticClass:
                                                "search-results--rating search-results--rating-container stars d-none d-md-flex"
                                            })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.showTripadvisorReviews(result)
                                      ? _c("tripadvisor-rating", {
                                          staticClass:
                                            "search-results--rating search-results--rating-container stars d-flex d-md-none",
                                          attrs: {
                                            location_link:
                                              result.tripadvisor_reviews
                                                .web_url,
                                            rating:
                                              result.tripadvisor_reviews.rating,
                                            reviews_count:
                                              result.tripadvisor_reviews
                                                .num_reviews,
                                            font_size: "12px",
                                            bubbleClass: "margin-right-1x",
                                            show_icon: true
                                          }
                                        })
                                      : _c("div", {
                                          staticClass:
                                            "search-results--rating search-results--rating-container stars d-flex d-md-none"
                                        }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-wrap flex-xl-nowrap justify-content-xl-between flex-grow-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "search-results--facilities"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "search-results--distance"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      result.distance_from_airport
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      result.unit == "mile"
                                                        ? "Mi"
                                                        : "Km"
                                                    ) +
                                                    " from airport\n                    "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "search-results--amenities d-none d-md-block"
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  _vm._l(
                                                    result.amenities,
                                                    function(amenity) {
                                                      return amenity.icon &&
                                                        _vm.shouldShowAmenity(
                                                          amenity.type
                                                        )
                                                        ? _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "margin-right-2x"
                                                            },
                                                            [
                                                              _c("image-bg", {
                                                                staticClass:
                                                                  "amenity",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  "data-placement":
                                                                    "top",
                                                                  title:
                                                                    amenity.display_name,
                                                                  src:
                                                                    amenity.icon
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    }
                                                  )
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "ul",
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "f15 margin-right-3x"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-check green",
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          "\n                          Free Shuttle Included\n                        "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.query
                                                      .reservation_type ==
                                                    "parking"
                                                      ? _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "f15 margin-right-3x"
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-check green",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            }),
                                                            _vm._v(
                                                              "\n                          Free Cancellation\n                        "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      result.facility_custom_amenities,
                                                      function(amenity) {
                                                        return _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "f15 margin-right-3x"
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-check green",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              }
                                                            }),
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  amenity
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      result.room_custom_amenities,
                                                      function(amenity) {
                                                        return _vm.shouldShowAmenity(
                                                          "room"
                                                        )
                                                          ? _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "f15 margin-right-3x"
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-check green",
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                }),
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      amenity
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      result.parking_custom_amenities,
                                                      function(amenity) {
                                                        return _vm.shouldShowAmenity(
                                                          "parking"
                                                        )
                                                          ? _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "f15 margin-right-3x"
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-check green",
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                }),
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      amenity
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-none d-md-block search-results--book"
                                          },
                                          [
                                            _vm.$route.name == "airport"
                                              ? _c("div", [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-warning search-results--price-button check-prices",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.openSearchModal(
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Check Prices\n                      "
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _c("div", [
                                                  result.error
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                result.error
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    : _c("div", [
                                                        result.sold_out
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-secondary disabled",
                                                                attrs: {
                                                                  disabled: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          SOLD OUT \n                          "
                                                                ),
                                                                result.price
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "\n                            | " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "currency"
                                                                            )(
                                                                              result.price,
                                                                              _vm.currencySymbol(
                                                                                !_vm.currencyCode
                                                                                  ? result.currency_code
                                                                                  : _vm.currencyCode
                                                                              )
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ])
                                                                  : _vm._e()
                                                              ]
                                                            )
                                                          : _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-warning search-results--price-button"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          TOTAL |\n                          "
                                                                ),
                                                                result.price
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "currency"
                                                                          )(
                                                                            result.price,
                                                                            _vm.currencySymbol(
                                                                              !_vm.currencyCode
                                                                                ? result.currency_code
                                                                                : _vm.currencyCode
                                                                            )
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-spinner fa-spin"
                                                                      })
                                                                    ])
                                                              ]
                                                            ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "f12 text-dark margin-top-1x"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Inc. taxes & fees\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                ])
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    result.warning
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-warning text-right"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(result.warning) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "search-result--book-container"
                                  },
                                  [
                                    _vm.$route.name == "airport"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "search-results--price-label d-md-none"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-right clickable f14 check-prices",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.openSearchModal(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    Check Prices\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "search-results--price-label d-md-none text-right"
                                          },
                                          [
                                            result.error
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-danger f12"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(result.error) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _c("div", [
                                                  result.price
                                                    ? _c("div", [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.currencySymbol(
                                                                !_vm.currencyCode
                                                                  ? result.currency_code
                                                                  : _vm.currencyCode
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          _vm._s(
                                                            result.price.toFixed(
                                                              0
                                                            )
                                                          )
                                                        ),
                                                        _c("sup", [
                                                          _vm._v(
                                                            _vm._s(
                                                              result.price
                                                                .toFixed(2)
                                                                .split(".")[1]
                                                            )
                                                          )
                                                        ])
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !result.price &&
                                                  !result.sold_out
                                                    ? _c("div", [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-spinner fa-spin"
                                                        })
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  result.price
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f10 text-center text-dark"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Inc. taxes & fees\n                    "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  result.sold_out
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      S\\O\n                    "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ])
                                          ]
                                        )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      !_vm.filteredResults.length
                        ? _c("li", { staticClass: "search-results--item" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "search-no-result text-center text-danger"
                              },
                              [
                                _vm._v(
                                  "\n            Please select another airport, There are no connected locations.\n          "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("how-it-works"),
                  _vm._v(" "),
                  _c("section", { staticClass: "airport-description" }, [
                    _c("div", { staticClass: "container" }, [
                      _c("h2", { staticClass: "mb-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.airport.name) +
                            " Airport Information"
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "text-content",
                        domProps: { innerHTML: _vm._s(_vm.airport.description) }
                      })
                    ])
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.filtersActive
                ? _c("search-filters", {
                    attrs: {
                      criteria: _vm.query,
                      "price-slider": _vm.priceSlider,
                      "rating-read-only": false,
                      "rating-increment-value": 0.5,
                      "rating-max-value": 5,
                      "distance-slider": _vm.distanceSlider,
                      "number-of-spots": _vm.numberOfSpots,
                      amenities: _vm.sharedAmenities,
                      "price-values": _vm.filters.price,
                      "distance-value": _vm.filters.distance
                    },
                    on: {
                      hideFilters: function($event) {
                        _vm.toggleFilters()
                      },
                      filtersApply: _vm.applyFilters,
                      amenitiesChanged: _vm.updateAmenities
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "map-col",
              class: _vm.mapActive === true ? "active-sm-xs" : "none"
            },
            [
              _c(
                "no-ssr",
                [
                  _c("google-maps-results", {
                    staticClass: "map-container",
                    attrs: {
                      markers: _vm.markers,
                      center: _vm.center,
                      icon: "/assets/images/pin.png",
                      "active-icon": "/assets/images/pin-active.png",
                      "inactive-icon": "/assets/images/pin-inactive.png",
                      zoom: 12
                    },
                    on: {
                      mapMoved: _vm.onMapMoved,
                      mouseEnterIcon: _vm.onMouseEnterIcon,
                      mouseOutIcon: _vm.onMouseOutIcon,
                      clickIcon: _vm.onClickIcon
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      this.canShowSearchModal
        ? _c("search-modal", {
            ref: "searchModal",
            staticClass: "search-modal",
            attrs: {
              "form-data": _vm.query,
              airport: _vm.airport,
              "on-close-modal": _vm.onCloseModal,
              "route-name": "search"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.filtersActive
        ? _c(
            "div",
            {
              staticClass: "results-holder wrapper wrapper-overflow d-lg-none"
            },
            [
              _c(
                "div",
                {
                  ref: "searchFiltersReponsive",
                  staticClass: "search-filters--responsive"
                },
                [
                  _c("search-filters", {
                    staticClass: "search-results--filters-modal",
                    attrs: {
                      criteria: _vm.query,
                      "price-slider": _vm.priceSlider,
                      "rating-read-only": false,
                      "rating-increment-value": 0.5,
                      "rating-max-value": 5,
                      "distance-slider": _vm.distanceSlider,
                      "number-of-spots": _vm.numberOfSpots,
                      amenities: _vm.sharedAmenities,
                      "price-values": _vm.filters.price,
                      "distance-value": _vm.filters.distance
                    },
                    on: {
                      hideFilters: function($event) {
                        _vm.toggleFilters()
                      },
                      filtersApply: _vm.applyFilters,
                      amenitiesChanged: _vm.updateAmenities
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "toggle-filters",
          on: {
            click: function($event) {
              _vm.toggleFilters()
            }
          }
        },
        [
          _c("i", {
            staticClass: "fa fa-filter",
            attrs: { "aria-hidden": "true" }
          }),
          _vm._v("\n    Filter results\n  ")
        ]
      ),
      _vm._v(" "),
      _c("confirm", { ref: "confirm", attrs: { type: "warning" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4979c3ba", { render: render, staticRenderFns: staticRenderFns })
  }
}