import { user } from 'services'
import { currencySymbol } from 'filters'
import signupModal from './partials/signupModal'
import loginModal from './partials/loginModal'
import resetPasswordModal from './partials/resetPasswordModal'
import flashMessage from 'components/flashMessage'
import moment from 'moment'
import Bus from 'bus'

// import hasPermits from 'directives/hasPermits'

const directives = {
  // hasPermits
}

const components = {
  signupModal,
  loginModal,
  resetPasswordModal,
  flashMessage
}

const filters = {
  currencySymbol
}

const computed = {
  selectedCurrency () {
    return _.cloneDeep(this.$store.state.currencies.selected);
  },
  currentUser () {
    this.$store.dispatch('user.getUser');
    return _.cloneDeep(this.$store.state.user.data);
  }
}


const methods = {
  changeRoute (route) {
    this.$store.dispatch('nav.changeRoute', route);
  },
  logout () {
    this.$store.dispatch('user.logout');
    this.$router.replace({name: 'login'});
  },
  openSignupModal () {
    this.canShowSignupModal = true;
    this.$nextTick(() => {
      this.$refs.signupModal.open();
    })
  },
  onCloseModal () {
    this.canShowSignupModal = false;
    this.canShowloginModal = false;
  },
  openLoginModal () {
    this.canShowLoginModal = true;
    this.$nextTick(() => {
      this.$refs.loginModal.open();
    })
  },

  openPasswordResetModal () {
    console.log("this.$refs.resetPasswordModal", this.$refs.resetPasswordModal)
    this.$refs.resetPasswordModal.open();
  },
  closePasswordResetModal () {
    this.$refs.resetPasswordModal.close();
  },
  onSignupEnd () {
    this.canShowLoginModal = false;
    this.$router.replace({name: 'login'});
  },
  onCloseLoginModal () {
    this.canShowLoginModal = false;
  },
  isFullContainer (routeName) {
    return this.$route.name == routeName;
  },
  hideFooter (routeName) {
    return this.isFullContainer(routeName);
  },
  openSideMenu () {
  },
  setCurrency (currency) {
    if (!currency) {
      this.$store.dispatch('currencies.save',null);
      return;
    }
    this.$store.dispatch('currencies.save',currency.code);
  },
}

const watch = {
  
}

export default {
  props: ['noFooter'],
  methods,
  directives,
  filters,
  computed,
  components,
  watch,
  data () {
    return {
      menuOpen: false,
      currencies: [],
      loading: false,
      canShowSignupModal: false,
      canShowLoginModal: false,
      currentYear: moment().year(),
    }
  },
  created () {
    this.changeRoute(this.$route.name);
  },
  mounted () {
    this.loading = true;
    this.$store.dispatch('currencies.getAll').then(() => {
      this.currencies = _.cloneDeep(this.$store.state.currencies.all);
      this.loading = false;
    });
    Bus.$on('details.openLoginModal', () => {
      this.canShowLoginModal = true;
      this.$nextTick(() => {
        this.$refs.loginModal.open();  
      })
      
    });
    this.$store.dispatch('currencies.get');
  }
}
